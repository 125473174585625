/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.min.css";
@import "primeng/resources/themes/lara-dark-blue/theme.css";
@import "styles/area-theme";
@import "styles/classes";
@import "styles/overwrite-classes";
@import "styles/icons";
@import "styles/tablet";
@import "styles/mobile";

$headerHeight: 50px;
$footerHeight: 25px;

::-webkit-scrollbar {
  width: 10px;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 10px 0;
}

.header-with-sub-header {
  height: 75px !important;
}

header {
  height: $headerHeight;

  .header-wrapper {
    display: flex;
    flex-direction: row;

    .logo-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .p-button.p-button-text {
        border: none;
      }
    }

    .time {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    .header {
      display: flex;
      gap: 10px;
      align-items: center;

      overflow: hidden;

      .app-name {
        display: flex;
        align-items: center;
      }

      .logo {
        padding: 5px 0;
        height: 45px;

        img {
          height: 100%;
        }
      }

      .app-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .header-menu {
      text-align: right;
      flex: 1;
      justify-content: flex-end;
      padding-right: 10px;
    }

    .p-menu-overlay {
      width: 200px !important;
    }
  }
}

main {
  height: 100vh;
  overflow: hidden;

  .app {
    overflow: hidden;

    aside {
      width: 200px;
      overflow-y: auto;

      .menu {
        .p-submenu-header {
          background: none !important;
          border: none !important;
        }
      }
    }

    .component {
      flex: 1;
      overflow-y: auto;
    }
  }


  footer {
    height: $footerHeight;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.app {
  min-height: calc(100vh - $headerHeight - $footerHeight);
  display: flex;
  flex: 1;

  .sidebar-open {
    display: flex;
    flex-direction: column;
  }

  .sidebar-closed {
    display: none;
  }

  .component {
    width: 100%;
    padding: 5px;

    .content-wrapper {
      height: 100%;

      .content {
        min-height: 100%;

        form {
          input {
            width: 100%;
          }
        }

        td,
        th {
          p-dropdown,
          .p-dropdown {
            width: 100%;
          }
        }


        .table-caption {
          display: flex;

          .table-caption-table-info {
            display: flex;
            flex: 1;
            flex-direction: row;
            gap: 25px;
            align-items: center;

            .table-caption-text {
              display: flex;
              align-items: center;

              font-weight: 400;
            }

            .table-caption-column-select {
              flex: 1;
            }
          }

          .table-caption-search {
          }
        }

        .table-header-label {
          display: flex;

          .table-header-text {
            flex: 1;
          }

          .table-header-icon {
          }
        }
      }
    }
  }
}

.context-menu-width {
  width: auto !important;
}

input:not(.p-calendar input),
.p-password input {
  border-radius: 10px;

  &:focus {
    box-shadow: none !important;
  }

  &:hover,
  &:active,
  &:enabled:focus {
  }
}

.p-calendar input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-wrapper {
  display: flex !important;
  align-items: center !important;
}

.wrapper-right {
  justify-content: flex-end !important;
}

.btn {
}

.icon-btn {
  .p-button {
    background: transparent !important;
    border: 0 !important;
  }

  .pi {
    font-size: 1.275rem;
  }
}

.text-btn {
  border: 0 !important;
  padding: 0 !important;

  &:hover {
    border: 0;
  }
}

.icon-btn-without-hover {
  &:hover {
    border: 0 !important;
  }
}

.icon-btn {
  .p-button-label {
    transition-duration: unset !important;
  }

  &:hover {
    border: 0;
  }
}

.danger-btn,
.danger-icon-btn {
  border: 0 !important;

  &:hover {
    border: 0;
  }

  .pi {
    font-size: 1.275rem;
  }
}

.hidden-column {
  display: none;
}

.table-edit-input,
.p-multiselect {
  width: 100% !important;
}

.spinner-component-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center;

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    /* Transparent Overlay */
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.53);
    }
  }
}

.steps {
  display: flex;
  width: 100%;
  justify-content: space-between;

  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;

  .steps-items {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.p-menu {

  width: unset !important;

  .p-menuitem-text {
    line-height: unset !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.p-panelmenu {
  .p-icon-wrapper {
    order: 1; // to be the first item on right side.
    svg {
      margin-right: unset !important;
    }
  }

  .p-menuitem-text {
    flex: 1;
  }

  .p-panelmenu-content {
    border: none !important;
    background: transparent !important;
  }

  .p-panelmenu-panel {
    .p-panelmenu-header-content {
      border: none !important;
      background: transparent !important;
    }
  }
}

.p-datatable-wrapper {
  overflow: visible !important;
}

.add-chip {
  background-color: transparent !important;
  border: 1px dashed #aaa !important;
}

.p-dialog-content {
  overflow: visible !important;
}

app-gameday-player-assignment-dialog .p-dialog-content,
.p-dialog-mask.p-component-overlay {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.p-chip {
  padding: 5px !important;

  .p-chip-text {
    margin: 0 !important;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 5px !important;
}

.hidden-columns-select {
  border: none !important;

  .p-multiselect-label {
    padding: 0 !important;
  }

  .p-multiselect-label-container {
    .p-multiselect-label-empty {
      visibility: visible !important;
    }
  }

  .p-multiselect-trigger {
    display: none !important;
  }

  .p-overlay {
    left: -100px !important;
  }

  .active-while-panel-open {
    .p-button {
      color: $textColorHighlight;
    }
  }
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 5px !important;
}

p-button {
  .p-button.p-button-icon-only {
    padding: 0 !important;
  }
}

p-selectbutton {
  .p-selectbutton {
    .p-button.p-highlight {
      width: 50%;
    }

    .p-button {
      width: 50%;
    }
  }
}

p-tristatecheckbox {
  display: flex;
  align-items: center;
}

.custom-dialog {
  .p-dialog-content {
    padding: 0;
  }
}

app-terminal {
  .p-dialog {
    .p-dialog-header {
      padding: 5px;

      background-color: $backgroundColor2;
    }
  }

  .p-terminal {
    padding: 5px;
    background-color: $backgroundColor;

    div {
      white-space: pre-wrap;
      font-family: sans-serif;
    }

    .p-terminal-prompt-container {
      display: flex;
      gap: 5px;
    }

    .p-terminal-input {
      border-radius: 0;
    }
  }
}

.ng-invalid[required] {
  border: 1px solid $errorColor;
}

.simplified-checkin-dialog {
  .p-dialog-content {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.3rem;
  }
}

.active-game-day-dialog {
  width: 75vw;
  min-height: 85vh;
  top: -15px;

  .p-dialog-header {
    background-color: $backgroundColor2;
  }

  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    padding: 10px;
  }
}

.checkin-btn {
  font-size: 3rem !important;

  span,
  span .pi {
    font-size: inherit !important;
  }
}

.p-column-title {
  margin-right: 5px;
}
